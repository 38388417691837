<template>
  <v-form
    ref="form"
    @submit.prevent="validateForm"
  >
    <v-row dense>
      <v-col
        cols="12"
        md="2"
      >
        <v-autocomplete
          v-model="filter.product_reference"
          :items="codes.items"
          @change="getPrintsByCode(filter.product_reference)"
          :search-input.sync="filter.codes"
          item-text="product_reference"
          outlined
          rounded
          dense
          flat
          label="Código"
        >
          <template v-slot:no-data>
            <div class="pa-2">
              <v-progress-linear
                v-if="codes.isLoading"
                indeterminate
                color="primary"
              />
              <h6
                v-else
                class="text-subtitle-2 text-center"
              >
                Nenhuma gravação encontrada
              </h6>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          label="Nome"
          placeholder="Nome do produto"
          v-model="filter.product_name"
          color="primary"
          outlined
          rounded
          dense
          flat
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          @focus="getPrints"
          label="Gravação"
          v-model="filter.print"
          :items="print.items"
          item-text="name"
          item-value="id"
          color="primary"
          :loading="print.customFilter"
          outlined
          rounded
          dense
          flat
        >
          <template v-slot:no-data>
            <div class="pa-2">
              <v-progress-linear
                v-if="print.isLoading"
                indeterminate
                color="primary"
              />
              <h6
                v-else
                class="text-subtitle-2 text-center"
              >
                Nenhuma gravação encontrada
              </h6>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          label="Status do Fornecedor"
          v-model="filter.vendor_status"
          :items="vendor_status_table"
          item-text="title"
          item-value="id"
          color="primary"
          outlined
          rounded
          dense
          flat
        >
          <template v-slot:item="{ item }">
            <v-list-item-avatar size="20">
              <v-icon
                small
                :color="item.color"
                >{{ item.icon }}</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          @focus.once="getCategories"
          @input="getPrintsByCategory"
          label="Categoria"
          v-model="filter.categories"
          :items="category.items"
          item-text="name"
          item-value="id"
          rounded
          dense
          color="primary"
          outlined
          flat
        >
          <template v-slot:no-data>
            <div class="pa-2">
              <v-progress-linear
                v-if="category.isLoading"
                indeterminate
                color="primary"
              />
              <h6
                v-else
                class="text-subtitle-2 text-center"
              >
                Nenhuma categoria encontrada
              </h6>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          @focus.once="getSubCategories"
          label="Subcategoria"
          v-model="filter.subcategories"
          :items="subcategory.items"
          item-text="name"
          item-value="id"
          rounded
          dense
          color="primary"
          outlined
          flat
        >
          <template v-slot:no-data>
            <div class="pa-2">
              <v-progress-linear
                v-if="subcategory.isLoading"
                indeterminate
                color="primary"
              />
              <h6
                v-else
                class="text-subtitle-2 text-center"
              >
                Nenhuma subcategoria encontrada
              </h6>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          @focus.once="getSegments"
          label="Segmento"
          v-model="filter.segment"
          :items="segment.items"
          item-text="name"
          item-value="id"
          rounded
          dense
          color="primary"
          outlined
          flat
        >
          <template v-slot:no-data>
            <div class="pa-2">
              <v-progress-linear
                v-if="segment.isLoading"
                indeterminate
                color="primary"
              />
              <h6
                v-else
                class="text-subtitle-2 text-center"
              >
                Nenhum segmento encontrado
              </h6>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          @focus.once="getSpecialDates"
          label="Datas especiais"
          v-model="filter.special_dates"
          :items="specialDate.items"
          item-text="name"
          item-value="id"
          rounded
          dense
          color="primary"
          outlined
          flat
        >
          <template v-slot:no-data>
            <div class="pa-2">
              <v-progress-linear
                v-if="specialDate.isLoading"
                indeterminate
                color="primary"
              />
              <h6
                v-else
                class="text-subtitle-2 text-center"
              >
                Nenhuma data especial encontrada
              </h6>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <h6 class="text-subtitle-2">Preço de venda</h6>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-text-field
          :disabled="!filter.print"
          label="De :"
          placeholder="Preço de venda inicial"
          v-model="filter.from_cost"
          color="primary"
          outlined
          rounded
          dense
          flat
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-text-field
          :disabled="!filter.print"
          label="Até :"
          placeholder="Preço de venda limite"
          v-model="filter.to_cost"
          color="primary"
          outlined
          rounded
          dense
          hide-details
          flat
        />
      </v-col>
      <v-col
        align="right"
        cols="12"
        md="10"
      >
        <v-btn
          @click="clearFilter"
          rounded
          dense
          color="primary"
          outlined
          dark
          class="mx-2"
        >
          Limpar filtro
        </v-btn>

        <v-btn
          type="submit"
          rounded
          dense
          color="primary"
          class="mx-2"
        >
          Filtrar
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense> </v-row>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    filter: { codes: null, quantity: null },
    codes: {
      items: [],
      isLoading: true,
    },
    category: {
      items: [],
      isLoading: true,
    },
    subcategory: {
      items: [],
      isLoading: true,
    },
    segment: {
      items: [],
      isLoading: true,
    },
    specialDate: {
      items: [],
      isLoading: true,
    },
    print: {
      items: [],
      isLoading: true,
      customFilter: false,
    },
    vendor_status_table: [
      { id: 1, title: 'Ok', icon: 'fas fa-check', color: 'success' },
      { id: 2, title: 'Consultar', icon: 'fas fa-search', color: 'blue' },
      { id: 3, title: 'Sem estoque', icon: 'fas fa-box-open', color: 'error' },
      {
        id: 4,
        title: 'Fora de linha',
        icon: 'fas fa-times-circle',
        color: 'grey',
      },
    ],
    modalFrom: false,
    modalTo: false,
    dialogFrom: false,
    dialogTo: false,
  }),
  watch: {
    'filter.print'(newvalue) {
      this.$emit('printSelected', newvalue)
    },
    'filter.codes'(newvalue) {
      this.searchCodes(newvalue)
    },
  },
  computed: {
    dateFromConverted: {
      get: function () {
        if (this.filter.dateFrom) {
          let BR_dateFormat = this.filter.dateFrom.split('-')
          BR_dateFormat = BR_dateFormat.reverse()
          BR_dateFormat = BR_dateFormat.join('/')
          return BR_dateFormat
        } else {
          return ''
        }
      },
      set: function (value) {
        return (this.filter.dateFrom = value)
      },
    },
    dateToConverted: {
      get: function () {
        if (this.filter.dateTo) {
          let BR_dateFormat = this.filter.dateTo.split('-')
          BR_dateFormat = BR_dateFormat.reverse()
          BR_dateFormat = BR_dateFormat.join('/')
          return BR_dateFormat
        } else {
          return ''
        }
      },
      set: function (value) {
        return (this.filter.dateTo = value)
      },
    },
  },
  methods: {
    searchCodes(code) {
      this.$api
        .get(`codes/${code}`)
        .then((res) => {
          this.codes.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    validateForm() {
      const isValid = this.$refs.form.validate()

      let code = this.filter.product_reference

      if (code && code.length >= 7) {
        this.getPrintsByCode(code)
      }

      if (isValid) this.$emit('filter', this.filter)
    },
    clearFilter() {
      this.filter = {}
      this.print.items = []
      this.$emit('clearFilter')
    },
    getPrintsByCategory(e) {
      this.print.customFilter = true
      this.$api
        .get(`product_prints_by_category/${e}`)
        .then((res) => {
          this.print.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.print.customFilter = false
        })
    },
    getPrintsByCode(code) {
      this.print.customFilter = true
      this.$api
        .get(`product_prints_by_code/${code}`)
        .then((res) => {
          this.print.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.print.customFilter = false
        })
    },
    getCategories() {
      this.$api
        .get('categories/main_categories')
        .then((res) => {
          this.category.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.category.isLoading = false
        })
    },
    getSubCategories() {
      this.$api
        .get('categories/subcategories')
        .then((res) => {
          this.subcategory.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.subcategory.isLoading = false
        })
    },
    getSegments() {
      this.$api
        .get('categories/segments')
        .then((res) => {
          this.segment.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.segment.isLoading = false
        })
    },
    getSpecialDates() {
      this.$api
        .get('categories/special_dates')
        .then((res) => {
          this.specialDate.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.specialDate.isLoading = false
        })
    },
    getPrints() {
      if (this.print.items.length == 0) {
        this.print.isLoading = true
        this.$api
          .get('product_prints')
          .then((res) => {
            this.print.items = res.data.data
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            this.print.isLoading = false
          })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.primary-border {
  border: 1px solid var(--v-primary-base);
}
</style>
